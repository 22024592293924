import React from 'react';
import { Link } from 'react-router-dom';
import './ArticleTemplate.css';

const Article4 = () => {
    return (
        <div className="article-template">

            <main className="article-template-content">
                <br /><br /><br /><br />
                <h1 className="article-template-title">好きな曲10選</h1>
                <p className="article-template-date">2024/10/25</p>
                <br /><br /><br /><br />
                <p>あ</p>
                <br /><br />
                <p>アイドルに毎日を支えられているみなさんこんにちは</p>
                <br /><br />
                <p>またまた碌でもない記事を書きます。</p>
                <br /><br />
                <p>みなさんアイドルは好きですか？好きですよね。</p>
                <br /><br />
                <p>僕は卒業研究でもアイドルを研究しちゃうくらい大好きなんですよ。</p>
                <br /><br />
                <p>男の人の嫌な趣味ランキング第１位（ぼく調べ）に君臨しているアイドルオタクですが、そんなこと知ったこっちゃないです。自分に正直でいるべきだと思います。</p>
                <br /><br />
                <p>僕のアイドルオタクの歴史を辿ると長くなってしまうので一旦、個人的に最近アツいアイドルでも紹介させてください。</p>
                <br /><br />
                <p>- 透色ドロップ</p>
                <br /><br />
                <p>いわゆる「地下アイドル」と呼ばれるカテゴリに分類されるアイドルグループですが、今めちゃくちゃアツいです。楽曲のクオリティ、歌声含めパフォーマンス含め地上アイドルに匹敵するレベルと言っても過言じゃないです。</p>
                <br /><br />
                <p>特に好きな曲は「最愛」です。ここにリンク貼りますので、１回見てきなさい。</p>
                <br /><br />
                <p>- ≒JOY</p>
                <br /><br />
                <p>あの指原莉乃がプロデュースするアイドルです。このグループは、顔がめちゃくちゃ可愛い！！アイドルに顔を求めるな！派閥もそこそこいると思いますが、黙ってください。ビジュアルも戦闘力の一部なのです。</p>
                <br /><br />
                <p>特に好きな曲は「夢見る♡アイドル」です。香乃愛ちゃんと結婚しようと思ってます。</p>
                <br /><br />
                <p>- iLiFE!</p>
                <br /><br />
                <p>知ってる人も多いんじゃないかな！</p>
                <br /><br />
                <p>こちらも地下アイドルですが、もはや地上レベルで集客できちゃうアイドルです。運営が神・ファンサも神・全員元気で可愛い！そんなグループです。</p>
                <br /><br />
                <p>今1番ライブに行きたいアイドルです。上京したら死ぬほど行こうと思ってます。推せるうちに推そう。</p>
                <br /><br />
                <p>特に好きな曲は「のびしろグリッター」です。りりたんもぐもぐ。</p>
                <br /><br />
                <p>北海道にいるうちは、現場に行くってめっちゃ大変なんです。交通費がチケット代の10倍とか当たり前ですし、大学生の僕が頻繁にライブに行くことなんて到底できません。</p>
                <br /><br />
                <p>そこで賢い僕は、東京で就活をすることにしました。こんな不純な動機のやつでも意外とうまく行くものなのです。</p>
            </main>

            <footer className="article-template-footer">
                <Link to="/blog" className="article-template-back-to-blog">Back to Blog List</Link>
            </footer>
            <br /><br />
        </div>
    );
};

export default Article4;